<template>
    <div class="UiView ViewWrapper">
        <clv-head-meta :title="$t('UI')"></clv-head-meta>
        <view-header>
            <h1>UI <small>UI/UX Components and Patterns</small></h1>
        </view-header>
        <view-container>
            <div class="my-2 badges-tags-section">
                <h1>Badges and Tags</h1>
                <span class="gk-tag gk-tag-gray">This is a GK tag</span>
                <span class="gk-tag gk-tag-cool-gray">This is a GK tag</span>
                <span class="gk-tag gk-tag-warm-gray">This is a GK tag</span>
                <span class="gk-tag gk-tag-green">This is a GK tag</span>
                <span class="gk-tag gk-tag-red">This is a GK tag</span>
                <span class="gk-tag gk-tag-magenta">This is a GK tag</span>
                <span class="gk-tag gk-tag-purple">This is a GK tag</span>
                <span class="gk-tag gk-tag-purple-lighter">This is a GK tag</span>
                <span class="gk-tag gk-tag-blue">This is a GK tag</span>
                <span class="gk-tag gk-tag-cyan">This is a GK tag</span>
                <span class="gk-tag gk-tag-teal">This is a GK tag</span>
                <span class="communication-subscription-label">Com Sub Tag</span>

                <br/><br/>

                <span class="badge badge-accent1">Hello</span>
                <span class="badge badge-primary">Hello</span>
                <span class="badge badge-secondary">Hello</span>
                <span class="badge badge-success">Hello</span>
                <span class="badge badge-warning">Hello</span>
                <span class="badge badge-danger">Hello</span>
                <span class="badge badge-light">Hello</span>
                <span class="badge badge-dark">Hello</span>

                <br/><br/>

                <span class="badge badge-pill badge-accent1">Hello</span>
                <span class="badge badge-pill badge-primary">Hello</span>
                <span class="badge badge-pill badge-secondary">Hello</span>
                <span class="badge badge-pill badge-success">Hello</span>
                <span class="badge badge-pill badge-warning">Hello</span>
                <span class="badge badge-pill badge-danger">Hello</span>
                <span class="badge badge-pill badge-light">Hello</span>
                <span class="badge badge-pill badge-dark">Hello</span>
            </div>
            <br/><br/><br/><br/>
            <div class="my-2">
                <h1>Dock Panel, Modal, Dialog, Loader <small>Under construction</small></h1>
            </div>
            <br/><br/><br/><br/>
            <div class="my-2">
                <h1>Alerts</h1>
                <div class="alert alert-primary mb-2">
                    <p class="mb-0"><b>Alert</b> Lorem ipsum</p>
                </div>
                <div class="alert alert-secondary mb-2">
                    <p class="mb-0"><b>Alert</b> Lorem ipsum</p>
                </div>
                <div class="alert alert-accent1 mb-2">
                    <p class="mb-0"><b>Alert</b> Lorem ipsum</p>
                </div>
                <div class="alert alert-danger mb-2">
                    <p class="mb-0"><b>Alert</b> Lorem ipsum</p>
                </div>
                <div class="alert alert-warning mb-2">
                    <p class="mb-0"><b>Alert</b> Lorem ipsum</p>
                </div>
                <div class="alert alert-success mb-2">
                    <p class="mb-0"><b>Alert</b> Lorem ipsum</p>
                </div>
                <div class="alert alert-info mb-2">
                    <p class="mb-0"><b>Alert</b> Lorem ipsum</p>
                </div>
            </div>
            <br/><br/><br/><br/>
            <div class="my-2">
                <h1>Buttons</h1>
                <button class="btn btn-accent1 mr-1">This is a button</button>
                <button class="btn btn-primary mr-1">This is a button</button>
                <button class="btn btn-secondary mr-1">This is a button</button>
                <button class="btn btn-success mr-1">This is a button</button>
                <button class="btn btn-warning mr-1">This is a button</button>
                <button class="btn btn-danger mr-1">This is a button</button>
                <button class="btn btn-info mr-1">This is a button</button>
                <button class="btn btn-light mr-1">This is a button</button>
                <button class="btn btn-dark mr-1">This is a button</button>
                <button class="btn btn-secondary-alt mr-1">This is a button</button>
                <button class="btn btn-black mr-1">This is a button</button>
            </div>
            <br/><br/><br/><br/>
            <div class="my-2">
                <h1>Buttons SM</h1>
                <button class="btn btn-sm btn-accent1 mr-1">This is a button</button>
                <button class="btn btn-sm btn-primary mr-1">This is a button</button>
                <button class="btn btn-sm btn-secondary mr-1">This is a button</button>
                <button class="btn btn-sm btn-success mr-1">This is a button</button>
                <button class="btn btn-sm btn-warning mr-1">This is a button</button>
                <button class="btn btn-sm btn-danger mr-1">This is a button</button>
                <button class="btn btn-sm btn-info mr-1">This is a button</button>
                <button class="btn btn-sm btn-light mr-1">This is a button</button>
                <button class="btn btn-sm btn-dark mr-1">This is a button</button>
                <button class="btn btn-sm btn-secondary-alt mr-1">This is a button</button>
                <button class="btn btn-sm btn-black mr-1">This is a button</button>
            </div>
            <br/><br/><br/><br/>
            <div class="my-2">
                <h1>Buttons LG</h1>
                <button class="btn btn-lg btn-accent1 mr-1">This is a button</button>
                <button class="btn btn-lg btn-primary mr-1">This is a button</button>
                <button class="btn btn-lg btn-secondary mr-1">This is a button</button>
                <button class="btn btn-lg btn-success mr-1">This is a button</button>
                <button class="btn btn-lg btn-warning mr-1">This is a button</button>
                <button class="btn btn-lg btn-danger mr-1">This is a button</button>
                <button class="btn btn-lg btn-info mr-1">This is a button</button>
                <button class="btn btn-lg btn-light mr-1">This is a button</button>
                <button class="btn btn-lg btn-dark mr-1">This is a button</button>
                <button class="btn btn-lg btn-secondary-alt mr-1">This is a button</button>
                <button class="btn btn-lg btn-black mr-1">This is a button</button>
            </div>
        </view-container>
    </div>
</template>

<script>
export default {
    name: 'UiView'
};
</script>

<style lang="scss" scoped>
    .badges-tags-section {

        .badge {
            margin-right: .4em;
        }
    }

    .btn {
        margin-bottom: 0.5rem;
    }
</style>
